import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/authSlice";
import orgSlice from "./slices/orgSlice";
import projectSlice from "./slices/projectSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    organization: orgSlice,
    project :projectSlice
  },
});
