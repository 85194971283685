import { createSlice } from "@reduxjs/toolkit";
import {
  actionAddOrganization,
  actionGetOrganization,
} from "../services/orgService";

const orgSlice = createSlice({
  name: "organization",
  initialState: {
    getOrganizationLoader: false,
    organizations: [],
    addOrganizationLoader: false,
  },
  reducers: {},
  extraReducers: (buider) => {
    buider
      // actionGetOrganization State
      .addCase(actionGetOrganization.pending, (state) => {
        state.getOrganizationLoader = true;
      })
      .addCase(actionGetOrganization.fulfilled, (state, action) => {
        state.organizations = action.payload;
        state.getOrganizationLoader = false;
      })
      .addCase(actionGetOrganization.rejected, (state) => {
        state.getOrganizationLoader = false;
      })

      // actionAddOrganization state
      .addCase(actionAddOrganization.pending, (state) => {
        state.addOrganizationLoader = true;
      })
      .addCase(actionAddOrganization.fulfilled, (state, action) => {
        state.addOrganizationLoader = false;
      })
      .addCase(actionAddOrganization.rejected, (state) => {
        state.addOrganizationLoader = false;
      });
  },
});
export default orgSlice.reducer;
