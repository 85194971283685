import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { updateLocalAccessToken } from "../../utils/TokenService";
import { toast } from "sonner";

// Login with github API
export const actionLoginWithGithub = createAsyncThunk(
  "actionLoginWithGithub",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}auth/redirect/github`);
      const { data } = res;

      if (data) {
        window.location.replace(data); // This replaces the current URL with the new one
        return data;
      } else {
        toast.error("No URL returned from the server.", 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// Login with Google API
export const actionLoginWithGoogle = createAsyncThunk(
  "actionLoginWithGoogle",
  async () => {
    try {
      const res = await axios.get(`${BASE_URL}auth/redirect/google`);
      const { data } = res;
      // console.log('data',data)
      // return;
      if (data) {
        window.location.replace(data); // This replaces the current URL with the new one
        return data;
      } else {
        toast.error("No URL returned from the server.", 5);
      }
    } catch (error) {
      toast.error(error?.message, 5);
    }
  }
);

// callback and setToken with Oauth API
export const actionCallbackSetToken = createAsyncThunk(
  "actionCallbackSetToken",
  async ({ code, state, appname , navigate }, { rejectWithValue }) => {
    try {
      const res = await axios.get(
        `${BASE_URL}auth/callback/${appname}?code=${code}&state=${state}`
      );
      const { token } = res.data;
      if (token) {
        updateLocalAccessToken(token);
        navigate && navigate("/", { state: token });
        return token;
      } else {
        toast.error("No URL returned from the server.", 5);
        return rejectWithValue("No URL returned from the server.");
      }
    } catch (error) {
      toast.error(error?.message, 5);
      return rejectWithValue("No URL returned from the server.");
    }
  }
);

// Logout User
export const actionLogout = createAsyncThunk(
  "actionLogout",
  async ({ navigate, setVisibleLogoutModal }, { dispatch }) => {
    if (setVisibleLogoutModal) {
      setVisibleLogoutModal(false);
    }
    await new Promise((resolve) => {
      localStorage.removeItem("token");
      localStorage.removeItem("selectedOrgId");
      resolve();
    });
    navigate && navigate("/auth");
  }
);
