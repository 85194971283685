import { createSlice } from "@reduxjs/toolkit";
import {
  actionCallbackSetToken,
  actionLoginWithGithub,
} from "../services/authService";
import { getLocalAccessToken } from "../../utils/TokenService";

const authSlice = createSlice({
  name: "auth",
  initialState: {
    githubUrl: "",
    getGithubUrlLoader: false,
    token: getLocalAccessToken(),
    getTokenLoader: false,
    orgId: localStorage.getItem("selectedOrgId") || "",
  },
  reducers: {
    changeOrg(state, action) {
      state.orgId = action.payload;
      localStorage.setItem("selectedOrgId", action.payload);
    },
  },
  extraReducers: (buider) => {
    buider
      // actionLoginWithGithub State
      .addCase(actionLoginWithGithub.pending, (state) => {
        state.getGithubUrlLoader = true;
      })
      .addCase(actionLoginWithGithub.fulfilled, (state, action) => {
        state.githubUrl = action.payload;
        state.getGithubUrlLoader = false;
      })
      .addCase(actionLoginWithGithub.rejected, (state) => {
        state.getGithubUrlLoader = false;
      })

      // actionCallbackSetToken State
      .addCase(actionCallbackSetToken.pending, (state) => {
        state.getTokenLoader = true;
      })
      .addCase(actionCallbackSetToken.fulfilled, (state, action) => {
        state.token = action.payload;
        state.getTokenLoader = false;
      })
      .addCase(actionCallbackSetToken.rejected, (state) => {
        state.getTokenLoader = false;
      });
  },
});

export const { changeOrg } = authSlice.actions;

export default authSlice.reducer;
