export const updateLocalAccessToken = (token) => {
  try {
    if (token) {
      localStorage.setItem("token", token);
    } else {
      localStorage.removeItem("token"); // Remove if token is undefined
    }
  } catch (error) {
    return false;
  }
};

export const getLocalAccessToken = () => {
  try {
    const accessToken = localStorage.getItem("token");
    return accessToken;
  } catch (error) {
    return null;
  }
};
