import { createAsyncThunk } from "@reduxjs/toolkit";
import { BASE_URL } from "../../config/web-config";
import axios from "axios";
import { toast } from "sonner";
import { changeOrg } from "../slices/authSlice";
import { handleUnauthorized } from "./commonService";

// Action to get all organizations
export const actionGetOrganization = createAsyncThunk(
  "actionGetOrganization",
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const res = await axios.get(`${BASE_URL}organization/list/all-org`);
      const { data, status } = res;
      if (status === 200) {
        // dispatch(changeOrg())
        return data;
      } else {
        const customMsg = "No data";
        toast.error(customMsg, 5);
      }
    } catch (error) {
      console.log(error);
      if (error.status === 401) {
        // handleUnauthorized();
      } else {
        toast.error(error?.message, 5);
      }
    }
  }
);

//Action add organization
export const actionAddOrganization = createAsyncThunk(
  "actionAddOrganization",
  async ({ values, handleCancel }, { dispatch }) => {
    try {
      const response = await axios.post(
        `${BASE_URL}organization/create`,
        values
      );
      const { status, data } = response;
      if (status === 201) {
        toast.success("Successfully Created", 5);
        handleCancel && handleCancel();
        return data;
      }
    } catch (error) {
      toast.error("Organization already exist", 5);
    }
  }
);
