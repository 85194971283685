import { useEffect } from "react";
import { Navigate, Outlet, useNavigate } from "react-router-dom";

// Mock function to get the current year
const currentYear = new Date().getFullYear();

export const PrivateRoute = ({ component }) => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  /*  useEffect(() => { */
  if (!token) {
    return <Navigate to="/auth" replace />;
  }
  /*   }, [navigate, token]);
   */
  return component;
};

export const PublicRoute = () => {
  const token = localStorage.getItem("token");
  const navigate = useNavigate();

  /*   useEffect(() => {
    if (token) {
      navigate("/");
    }
  }, [navigate, token]); */

  if (token) {
    return <Navigate to="/" replace />;
  }

  return (
    <div>
      <section>
        <Outlet />
      </section>
      {/* Content ends Here */}
    </div>
  );
};
