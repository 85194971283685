// commonService.js
import { toast } from "sonner";
import { message } from "antd";

export const handleUnauthorized = async (
  message = "You are not authorized. Please log in again."
) => {
  await new Promise((resolve) => {
    localStorage.removeItem("token");
    localStorage.removeItem("selectedOrgId");
    resolve();
  });
  window.location.href = "/auth"; // redirect to login
};
