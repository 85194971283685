// import React from "react";
// import Highcharts from "highcharts";
// import HighchartsReact from "highcharts-react-official";

// // Importing Highcharts modules if needed
// import HighchartsMore from "highcharts/highcharts-more";
// HighchartsMore(Highcharts);

// const MyChartComponent = ({ categories, isDarkMode }) => {
//   const findEarliestDate = (categories) => {
//     if (!categories || !Array.isArray(categories) || categories.length === 0) {
//       return new Date(); // Return current date or any fallback date
//     }

//     const allDates = categories.flatMap((category) =>
//       category.data.map((value, index) => {
//         const baseDate = new Date(category.date);
//         const date = new Date(baseDate);
//         date.setDate(baseDate.getDate() + index);
//         return date;
//       })
//     );

//     // Ensure there are dates to find the minimum
//     if (allDates.length === 0) {
//       return new Date(); // Return current date or any fallback date
//     }

//     return new Date(Math.min(...allDates));
//   };
//   // Find the earliest date from categories
//   const earliestDate = findEarliestDate(categories);
//   const pointStart = earliestDate.getTime(); // Convert to timestamp

//   // Generate series data
//   const series =
//     categories &&
//     categories.map((category) => {
//       // Convert data to include dynamic dates
//       const baseDate = new Date(category.date); // Parse base date

//       const data = category.data.map((value, index) => {
//         const date = new Date(baseDate); // Create a new date object
//         date.setDate(baseDate.getDate() + index); // Increment days

//         // Get the timestamp in milliseconds since Unix epoch
//         return [date.getTime(), value];
//       });

//       return {
//         name: category.name,
//         data: data,
//         color:
//           category.name === "Critical"
//             ? "#FF4544"
//             : category.name === "High"
//             ? "#FF8D00"
//             : category.name === "Medium"
//             ? "#FFC800"
//             : category.name === "Low"
//             ? "#48C373"
//             : "#A0A0A0", // for Unassigned
//         marker: {
//           symbol: "circle",
//         },
//       };
//     });

//   // Chart options
//   const options = {
//     chart: {
//       type: "area",
//       backgroundColor: isDarkMode ? "#202020" : "#fff",
//     },
//     title: {
//       text: null,
//     },
//     yAxis: {
//       title: {
//         text: null,
//       },
//       labels: {
//         style: {
//           color: isDarkMode ? "#fff" : "#000",
//         },
//       },
//       tickAmount: 5,
//       gridLineWidth: 0,
//     },
//     xAxis: {
//       type: "datetime",
//       labels: {
//         enabled: true, // Enable labels to show dates
//         format: "{value:%d %b %Y}", // Format date labels
//       },
//     },
//     tooltip: {
//       shared: true,
//       crosshairs: true,
//       backgroundColor: "#202020",
//       style: {
//         color: "#fff",
//         fontSize: "12px",
//       },
//     },
//     plotOptions: {
//       area: {
//         stacking: "normal",
//         fillOpacity: 0.2,
//       },
//       series: {
//         pointStart: pointStart, // Set dynamically
//         pointIntervalUnit: "day",
//       },
//     },
//     legend: {
//       align: "left",
//       itemStyle: {
//         color: isDarkMode ? "#fff" : "#000",
//       },
//       itemHoverStyle: {
//         color: isDarkMode ? "#fff" : "#000",
//       },
//     },
//     credits: {
//       enabled: false,
//     },
//     series: series, // Apply the dynamically generated series
//   };

//   return (
//     <div>
//       <HighchartsReact highcharts={Highcharts} options={options} />
//     </div>
//   );
// };

// export default MyChartComponent;
import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

// Importing Highcharts modules if needed
import HighchartsMore from 'highcharts/highcharts-more';
HighchartsMore(Highcharts);

const MyChartComponent = ({ categories, isDarkMode }) => {
  // Find the earliest date from categories
  const findEarliestDate = (categories) => {
    if (!categories || !Array.isArray(categories) || categories.length === 0) {
      return new Date(); // Return current date or any fallback date
    }

    const allDates = categories.flatMap(category =>
      category.data.map((_, index) => {
        const baseDate = new Date(category.date);
        const date = new Date(baseDate);
        date.setDate(baseDate.getDate() + index);
        return date;
      })
    );

    // Ensure there are dates to find the minimum
    if (allDates.length === 0) {
      return new Date(); // Return current date or any fallback date
    }

    return new Date(Math.min(...allDates));
  };

  const earliestDate = findEarliestDate(categories);
  const pointStart = earliestDate.getTime(); // Convert to timestamp

  // Generate series data
  const series = categories &&
    categories.map((category) => {
      // Parse base date
      const baseDate = new Date(category.date);

      const data = category.data.map((value, index) => {
        const date = new Date(baseDate); // Create a new date object
        date.setDate(baseDate.getDate() + index); // Increment days

        // Get the timestamp in milliseconds since Unix epoch
        return [date.getTime(), value];
      });

      return {
        name: category.name,
        data: data,
        color:
          category.name === "Critical"
            ? "#FF4544"
            : category.name === "High"
            ? "#FF8D00"
            : category.name === "Medium"
            ? "#FFC800"
            : category.name === "Low"
            ? "#48C373"
            : "#A0A0A0", // for Unassigned
        marker: {
          symbol: "circle",
        },
      };
    });

  // Chart options
  const options = {
    chart: {
      type: "area",
      backgroundColor: isDarkMode ? "#202020" : "#fff",
    },
    title: {
      text: null,
    },
    yAxis: {
      title: {
        text: null,
      },
      labels: {
        style: {
          color: isDarkMode ? "#fff" : "#000",
        },
      },
      tickAmount: 5,
      gridLineWidth: 0,
    },
    xAxis: {
      type: "datetime",
      labels: {
        enabled: true, // Enable labels to show dates
        format: '{value:%d %b %Y}', // Format date labels
      },
    },
    tooltip: {
      shared: true,
      crosshairs: true,
      backgroundColor: "#202020",
      style: {
        color: "#fff",
        fontSize: "12px",
      },
    },
    plotOptions: {
      area: {
        stacking: "normal",
        fillOpacity: 0.2,
      },
      series: {
        pointStart: pointStart, // Set dynamically
        pointIntervalUnit: "day",
      },
    },
    legend: {
      align: "left",
      itemStyle: {
        color: isDarkMode ? "#fff" : "#000",
      },
      itemHoverStyle: {
        color: isDarkMode ? "#fff" : "#000",
      },
    },
    credits: {
      enabled: false,
    },
    series: series, // Apply the dynamically generated series
  };

  return (
    <div>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  );
};

export default MyChartComponent;
