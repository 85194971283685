import { ReloadOutlined } from "@ant-design/icons";
import {
  Button,
  Col,
  Collapse,
  Form,
  Image,
  Pagination,
  Radio,
  Row,
  Slider,
  Spin,
  Switch,
  Table,
  Tabs,
  Tooltip
} from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import filterIconBlue from "../../assets/img/filterIconBlue.svg";
import leftArrow from "../../assets/img/leftArrow.svg";
import noDataIcon from "../../assets/img/noDataIcon.svg";
import {
  actionGetProjectsDetailsComponents,
  actionGetProjectsDetailsIssues,
  actionGetProjectsDetailsServicesEOL
} from "../../store/services/projectService";
import SortIcon from "../common/SortIcon";

const ProjectDetail = () => {
  const [componentRiskScore, setComponentRiskScore] = useState(500);
  const [issuesRiskScore, setIssuesRiskScore] = useState(500);
  const [currentPageForComponent, setCurrentPageForComponent] = useState(1);
  const [itemsPerPageForComponent, setItemsPerPageForComponent] = useState(10);
  const [currentPageForIssues, setCurrentPageForIssues] = useState(1);
  const [itemsPerPageForIssues, setItemsPerPageForIssues] = useState(10);
  const [currentPageForServicesEOL, setCurrentPageForServicesEOL] = useState(1);
  const [itemsPerPageForServicesEOL, setItemsPerPageForServicesEOL] =
    useState(10);
  const [selectedSeverityComponent, setSelectedSeverityComponent] =
    useState("");
  const [selectedSeverityIssues, setSelectedSeverityIssues] = useState("");
  const [componentOutdatedVersion, setComponentOutdatedVersion] =
    useState(false);
  const [issuesOutdatedVersion, setIssuesOutdatedVersion] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location || {};
  const { projectId, projectName } = state || {}

  const activeTab = location.state?.tab || "1";

  const {
    getProjectsDetailsIssuesLoader,
    projectsDetailsIssues,
    getProjectsDetailsComponentsLoader,
    projectsDetailsComponents,
    totalProjectsComponent,
    totalProjectsIssues,
    projectsDetailsServicesEOL,
    totalProjectsServicesEOL,
    getProjectsDetailsServicesEOLLoader,
  } = useSelector((state) => state.project);
  const { components, severityCount } = projectsDetailsComponents || {};
  const { projects } = projectsDetailsIssues || {};
  const dispatch = useDispatch();

  const customEmpty = (
    <div className="flex flex-col items-center">
      <img src={noDataIcon} alt="No Data" />
      <p className="text-[#393939] text-[15px] font-montserratMedium">
        No Data Found
      </p>
    </div>
  );

  const onChangeComponentSeverity = (e) => {
    setSelectedSeverityComponent(e.target.value);
  };
  const onChangeIssuesSeverity = (e) => {
    setSelectedSeverityIssues(e.target.value);
  };

  const onChangeOutdatedComponentVersion = (e) => {
    setComponentOutdatedVersion(e);
  };
  const onChangeOutdatedIssuesVersion = (e) => {
    setIssuesOutdatedVersion(e);
  };

  const handleResetComponentData = () => {
    setSelectedSeverityComponent("");
    setComponentOutdatedVersion(false);
  };
  const handleResetIssusData = () => {
    setSelectedSeverityIssues("");
    setIssuesOutdatedVersion(false);
  };

  useEffect(() => {
    projectId &&
      dispatch(
        actionGetProjectsDetailsComponents({
          id: projectId,
          currentPageForComponent,
          itemsPerPageForComponent,
          selectedSeverity: selectedSeverityComponent,
          onlyOutdatedComponents: componentOutdatedVersion,
        })
      );
  }, [
    currentPageForComponent,
    itemsPerPageForComponent,
    selectedSeverityComponent,
    componentOutdatedVersion,
  ]);

  useEffect(() => {
    projectId &&
      dispatch(
        actionGetProjectsDetailsIssues({
          id: projectId,
          currentPageForIssues,
          itemsPerPageForIssues,
          selectedSeverity: selectedSeverityIssues,
          onlyOutdatedComponents: issuesOutdatedVersion,
        })
      );
  }, [
    state,
    currentPageForIssues,
    itemsPerPageForIssues,
    selectedSeverityIssues,
    issuesOutdatedVersion,
  ]);

  useEffect(() => {
    projectId &&
      dispatch(
        actionGetProjectsDetailsServicesEOL({
          id: projectId,
          currentPageForServicesEOL,
          itemsPerPageForServicesEOL,
        })
      );
  }, [state, currentPageForServicesEOL, itemsPerPageForServicesEOL]);

  const componentColumns = [
    {
      title: "Components",
      dataIndex: "name",
      fixed: "left",
      width: 200,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Version",
      width: 100,
      dataIndex: "version",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Group",
      width: 200,
      dataIndex: "group",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Internal",
      width: 100,
      dataIndex: "internal",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "License",
      width: 150,
      dataIndex: "license",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    // {
    //   title: "Vulnerability",
    //   width: 100,
    //   align: "center",
    //   dataIndex: "Vulnerability",
    //   render: (text) => <span className="ml-2">{text || "-"}</span>,
    // },
    {
      title: "Risk Score",
      width: 100,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      dataIndex: "lastInheritedRiskScore",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
  ];
  const issuesColumns = [
    {
      title: "Components",
      dataIndex: "component",
      fixed: "left",
      width: 200,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      render: (text) => <span className="ml-2">{text?.name || "-"}</span>,
    },
    {
      title: "Version",
      width: 100,
      dataIndex: "component",
      render: (text) => <span className="ml-2">{text?.version || "-"}</span>,
    },
    {
      title: "Group",
      width: 200,
      dataIndex: "component",
      render: (text) => <span className="ml-2">{text?.group || "-"}</span>,
    },
    {
      title: "Vulnerability",
      width: 250,
      ellipsis:true,
      dataIndex: "vulnerability",
      render: (text) => <span className="ml-2">{text?.vulnId || "-"}</span>,
    },
    {
      title: "Severity",
      width: 150,
      dataIndex: "vulnerability",
      render: (text) => <span className="ml-2">{text?.severity || "-"}</span>,
    },
    {
      title: "Analyzer",
      width: 250,
      ellipsis:true,
      dataIndex: "attribution",
      render: (text) => <span className="ml-2">{text?.analyzerIdentity || "-"}</span>,
    },
    {
      title: "Attributed On",
      width: 200,
      ellipsis:true,
      sorter: (a, b) => true,
      sortIcon: () => <SortIcon />,
      dataIndex: "attribution",
      render: (text) => <span className="ml-2">{text?.attributedOn || "-"}</span>,
    },
    {
      title: "Analysis",
      width: 250,
      ellipsis: true,
      dataIndex: "analysis",
      // render: (text) => <span className="ml-2">{text || "-"}</span>,
      render: (text) => <span className="ml-2">{"-"}</span>,
    },
    {
      title: "Suppressed",
      width: 150,
      dataIndex: "analysis",
      render: (text) => <span className="ml-2">{text?.isSuppressed ? "True" : "False"}</span>,
      // render: (text) => <span className="ml-2">{text?.isSuppressed || "-"}</span>,
    },
  ];
  const servicesEOLColumns = [
    {
      title: "Components",
      dataIndex: "productName",
      fixed: "left",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Version",
      dataIndex: "releaseVersion",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Group",
      dataIndex: "activeSupport",
      width: "40%",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Vulnerability",
      dataIndex: "latestReleaseVersion",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
    {
      title: "Severity",
      dataIndex: "severity",
      render: (text) => <span className="ml-2">{text || "-"}</span>,
    },
  ];

  const filterItemsComponent = [
    {
      key: "1",
      label: "Severity",
      children: (
        <div>
          <Radio.Group
            onChange={onChangeComponentSeverity}
            value={selectedSeverityComponent}
            className="flex flex-col gap-y-2"
          >
            <Radio
              value="critical"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Critical
            </Radio>
            <Radio
              value="high"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              High
            </Radio>
            <Radio
              value="medium"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Medium
            </Radio>
            <Radio
              value="low"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Low
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      key: "2",
      label: "Risk Score",
      children: (
        <div>
          <p className="text-[13px] lg:text-[15px] text-filterOption-text font-montserratRegular">
            Score between 0-1000
          </p>
          <Slider
            min={0}
            max={1000}
            value={componentRiskScore}
            onChange={setComponentRiskScore}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Outdated Version",
      children: (
        <div>
          <Switch
            value={componentOutdatedVersion}
            onChange={onChangeOutdatedComponentVersion}
          />
        </div>
      ),
    },
  ];
  const filterItemsIssues = [
    {
      key: "1",
      label: "Severity",
      children: (
        <div>
          <Radio.Group
            onChange={onChangeIssuesSeverity}
            value={selectedSeverityIssues}
            className="flex flex-col gap-y-2"
          >
            <Radio
              value="critical"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Critical
            </Radio>
            <Radio
              value="high"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              High
            </Radio>
            <Radio
              value="medium"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Medium
            </Radio>
            <Radio
              value="low"
              className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
            >
              Low
            </Radio>
          </Radio.Group>
        </div>
      ),
    },
    {
      key: "2",
      label: "Risk Score",
      children: (
        <div>
          <p className="text-[13px] lg:text-[15px] text-filterOption-text font-montserratRegular">
            Score between 0-1000
          </p>
          <Slider
            min={0}
            max={1000}
            value={issuesRiskScore}
            onChange={setIssuesRiskScore}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Outdated Version",
      children: (
        <div>
          <Switch
            value={issuesOutdatedVersion}
            onChange={onChangeOutdatedIssuesVersion}
          />
        </div>
      ),
    },
    // {
    //   key: "3",
    //   label: "‘Fixed in’ Available",
    //   children: (
    //     <div>
    //       <Checkbox.Group onChange={onChange} className="flex flex-col gap-y-2">
    //         <Checkbox
    //           value="yes"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           Yes
    //         </Checkbox>
    //         <Checkbox
    //           value="no"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           No
    //         </Checkbox>
    //       </Checkbox.Group>
    //     </div>
    //   ),
    // },
    // {
    //   key: "4",
    //   label: "Computed Fixability",
    //   children: (
    //     <div>
    //       <Checkbox.Group onChange={onChange} className="flex flex-col gap-y-2">
    //         <Checkbox
    //           value="fixable"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           Fixable
    //         </Checkbox>
    //         <Checkbox
    //           value="partially_fixable"
    //           className="text-[13px] lg:text-[15px] font-montserratRegular text-filterOption-text"
    //         >
    //           Partially Fixable
    //         </Checkbox>
    //       </Checkbox.Group>
    //     </div>
    //   ),
    // },
  ];


  const tabsItems = [
    {
      key: "1",
      label: "Component",
      children: (
        <div className="flex">
          <div className="w-[25%] lg:w-[20%] border border-border bg-box-secondary p-2 lg:p-4">
            <div className="flex gap-x-3 items-center justify-between">
              <div className="flex items-center gap-x-2">
                <img src={filterIconBlue} alt="filter" />
                <p className="text-[13px] lg:text-[15px] font-montserratMedium text-text-primary">
                  Filter
                </p>
              </div>
              <div>
                <Tooltip title="Reset">
                  <span
                    className="cursor-pointer"
                    onClick={handleResetComponentData}
                  >
                    <ReloadOutlined
                      style={{ color: "#1778f2", fontWeight: 600 }}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="mt-2 lg:mt-3">
              <Collapse
                defaultActiveKey={["1", "2", "3", "4"]}
                ghost
                collapsible="icon"
                items={filterItemsComponent}
                bordered={false}
              />
            </div>
          </div>
          <div className="w-[75%] lg:w-[80%] p-4 lg:p-8">
            <Table
              // rowKey={(record) => record.name}
              rowKey="id"
              pagination={false}
              scroll={{ x: "90vh" }}
              className="bg-transparent"
              columns={componentColumns}
              dataSource={components}
              // loading={getProjectsDetailsComponentsLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForComponent}
                pageSize={itemsPerPageForComponent}
                total={totalProjectsComponent}
                onChange={(page, pageSize) => {
                  setCurrentPageForComponent(page); // Update current page
                  setItemsPerPageForComponent(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "2",
      label: "Issues",
      children: (
        <div className="flex">
          <div className="w-[25%] lg:w-[20%] border border-border bg-box-secondary p-2 lg:p-4">
            <div className="flex gap-x-3 items-center justify-between">
              <div className="flex items-center gap-x-2">
                <img src={filterIconBlue} alt="filter" />
                <p className="text-[13px] lg:text-[15px] font-montserratMedium text-text-primary">
                  Filter
                </p>
              </div>
              <div>
                <Tooltip title="Reset">
                  <span
                    className="cursor-pointer"
                    onClick={handleResetIssusData}
                  >
                    <ReloadOutlined
                      style={{ color: "#1778f2", fontWeight: 600 }}
                    />
                  </span>
                </Tooltip>
              </div>
            </div>
            <div className="mt-2 lg:mt-3">
              <Collapse
                defaultActiveKey={["1", "2", "3", "4"]}
                ghost
                collapsible="icon"
                items={filterItemsIssues}
                bordered={false}
              />
            </div>
          </div>
          <div className="w-[75%] lg:w-[80%] p-4 lg:p-8">
            <Table
              // rowKey={(record) => record.name}
              rowKey="id"
              pagination={false}
              scroll={{
                x: "90vh",
                // y: window.innerWidth < 1500 ? "49vh" : "61vh",
              }}
              className="bg-transparent gray-scrollbar"
              columns={issuesColumns}
              dataSource={projects}
              // loading={getProjectsDetailsIssuesLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForIssues}
                pageSize={itemsPerPageForIssues}
                total={totalProjectsIssues}
                onChange={(page, pageSize) => {
                  setCurrentPageForIssues(page); // Update current page
                  setItemsPerPageForIssues(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "3",
      label: "Services - End of Life",
      children: (
        <>
          <div className="w-full">
            <div className="text-end mb-2 lg:mb-4">
              <Button
                onClick={() => {
                  navigate("/add-service", {
                    state: { projectId: projectId, projectName: projectName },
                  });
                }}
                type="primary"
                className="w-[150px] h-[45px] text-[12px] lg:text-[15px] font-montserratMedium"
              >
                Add Service
              </Button>
            </div>
            <Table
              // rowKey={(record, index) => index}
              rowKey="id"
              pagination={false}
              scroll={{
                x: "90vh",
                // y: window.innerWidth < 1500 ? "49vh" : "61vh",
              }}
              className="bg-transparent gray-scrollbar"
              columns={servicesEOLColumns}
              dataSource={projectsDetailsServicesEOL}
              // loading={getProjectsDetailsIssuesLoader}
              locale={{
                emptyText: customEmpty, // Apply the custom empty state
              }}
            />
            <div className="flex justify-end mt-2">
              <Pagination
                hideOnSinglePage
                current={currentPageForServicesEOL}
                pageSize={itemsPerPageForServicesEOL}
                total={totalProjectsServicesEOL}
                onChange={(page, pageSize) => {
                  setCurrentPageForServicesEOL(page); // Update current page
                  setItemsPerPageForServicesEOL(pageSize); // Optionally, handle items per page change
                }}
              />
            </div>
          </div>
        </>
      ),
    },
  ];

  const onChangeTabs = (key) => {
    console.log(key);
  };

  return (
    <>
      {projectId ?
        <Spin
          spinning={
            getProjectsDetailsIssuesLoader ||
            getProjectsDetailsComponentsLoader ||
            getProjectsDetailsServicesEOLLoader
          }
        >
          <Row gutter={[16, 10]} justify="space-between">
            <Col xs={24} sm={24} md={24} lg={24} className="flex items-center">
              <div className="cursor-pointer mr-2" onClick={() => navigate(-1)}>
                <Image src={leftArrow} alt="image" preview={false} />
              </div>
              <p>
                <span className="text-[15px] font-montserratMedium text-[#757575]">
                  Created Mon 8th Jul 2024 |{" "}
                </span>
                <span className="text-[15px] font-montserratMedium text-text-secondary">
                  Snapshot by recurring test 17 hour ago |{" "}
                </span>
                <span className="text-[15px] font-montserratMedium text-primary">
                  Retest now
                </span>
              </p>
            </Col>
            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-red-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {severityCount?.Critical || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Critical</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-pink-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {severityCount?.High || "0"}
                </h1>
                <p className="pt-2 text-[15px]">High</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-blue-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {severityCount?.Medium || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Medium</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-green-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {severityCount?.Low || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Low</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-grey-border">
                <h1 className="font-montserratMedium text-[17px]">
                  {severityCount?.Unassigned || "0"}
                </h1>
                <p className="pt-2 text-[15px]">Unassigned</p>
              </div>
            </Col>

            <Col xs={24} sm={12} md={12} lg={4}>
              <div className="border px-6 py-3 text-text-primary bg-box-secondary rounded-[10px] border-orange-border">
                <h1 className="font-montserratMedium text-[17px]">0</h1>
                <p className="pt-2 text-[15px]">Risk Score</p>
              </div>
            </Col>
          </Row>
          <div className="mt-2 md:mt-3 lg:mt-4">
            <Tabs
              type="card"
              defaultActiveKey={activeTab}
              items={tabsItems}
              onChange={onChangeTabs}
            />
          </div>
        </Spin>
        :
        <>
          <div className="flex flex-col items-center mt-10">
            <img src={noDataIcon} alt="No Data" />
            <p className="text-text-primary text-[15px] font-montserratMedium">
              Project details are not available
            </p>
            <div>
              <Button
                onClick={() => navigate("/project")}
                type="primary"
                className="delete-btn min-h-[40px] mt-2 lg:mt-4 cursor-pointer"
              >
                Back To Projects
              </Button>
            </div>
          </div>
        </>
      }

    </>
  );
};

export default ProjectDetail;
