import React, { useMemo } from "react";
import { RouterProvider } from "react-router-dom";
import { router } from "./config/router";
import { ThemeProvider } from "./components/common/ThemeContext";
import { ConfigProvider } from "antd";
import { useDispatch, useSelector } from "react-redux";
import setAuthToken from "./config/setAuthToken";
import { Toaster } from "sonner";

const App = () => {
  const { token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  useMemo(() => {
    if (token) {
      setAuthToken(token);
    }
  }, [token, dispatch]);
  return (
    <ThemeProvider>
      <Toaster
          visibleToasts={3}
          toastOptions={{
            classNames: {
              error: "my-toast-error",
              success: "my-toast-success",
              warning: "my-toast-warning",
              info: "my-toast-info",
              closeButton:
                "absolute -translate-x-1/2 -translate-y-1/2 left-[94%] right-0 top-1/2 cancel-button-style",
            },
          }}
          position="bottom-left"
          reverseOrder={false}
          expand={true}
          duration={4000}
          richColors={true}
          closeButton={true}
        />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
};

export default App;
